@import url(https://fonts.googleapis.com/css?family=Noto+Serif:regular,bold,italic&subset=latin,latin-ext);
@import url(https://fonts.googleapis.com/css?family=Tenali+Ramakrishna:regular,bold,italic&subset=latin,latin-ext);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  -webkit-align-items: center;
          align-items: center;
  background-color: black;
  color: white;
  display: -webkit-flex;
  display: flex;
  width: 100vw;
  height: 100vh;
}

.description {
  font-family: "Noto Serif";
  padding-top: 4rem;
}

.enjoy {
  display: block;
}

.personal-sites {
  font-size: 1.5rem;
  padding-top: 6rem;
}

.sidebar {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-family: "Noto Serif";
  font-size: 1rem;
  padding-left: 2rem;
  /* padding-top: 7rem; */
  width: 20%;
  text-align: left;
}

.leianne {
  font-family: "Noto Serif";
  font-size: 8rem;
}

.responsive {
  width: 100%;
  height: auto;
}

.carousel {
  width: 100%;
}

.carousel button {
  display: none;
}

.photoContainer {
  width: 80%;
}

a {
  color: white;
  padding-left: 0.3rem;
}
@media only screen and (max-width: 600px) {
  .App {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .sidebar {
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    padding-left: 0;
    padding-top: 0;
    width: 100%;
  }
}

/*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
  position: absolute;
  background: hsla(0, 0%, 100%, 0.75);
  border: none;
  color: #333;
}

.flickity-button:hover {
  background: white;
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19f;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: #333;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 10px;
}
.flickity-prev-next-button.next {
  right: 10px;
}
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

