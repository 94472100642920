@import url("https://fonts.googleapis.com/css?family=Noto+Serif:regular,bold,italic&subset=latin,latin-ext");
@import url("https://fonts.googleapis.com/css?family=Tenali+Ramakrishna:regular,bold,italic&subset=latin,latin-ext");

.App {
  align-items: center;
  background-color: black;
  color: white;
  display: flex;
  width: 100vw;
  height: 100vh;
}

.description {
  font-family: "Noto Serif";
  padding-top: 4rem;
}

.enjoy {
  display: block;
}

.personal-sites {
  font-size: 1.5rem;
  padding-top: 6rem;
}

.sidebar {
  display: flex;
  flex-direction: column;
  font-family: "Noto Serif";
  font-size: 1rem;
  padding-left: 2rem;
  /* padding-top: 7rem; */
  width: 20%;
  text-align: left;
}

.leianne {
  font-family: "Noto Serif";
  font-size: 8rem;
}

.responsive {
  width: 100%;
  height: auto;
}

.carousel {
  width: 100%;
}

.carousel button {
  display: none;
}

.photoContainer {
  width: 80%;
}

a {
  color: white;
  padding-left: 0.3rem;
}
@media only screen and (max-width: 600px) {
  .App {
    flex-direction: column;
  }

  .sidebar {
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    padding-left: 0;
    padding-top: 0;
    width: 100%;
  }
}
